import { useRef, useEffect } from 'react';
/**
 * Creates DOM element to be used as React root.
 */

function createRootElement(id) {
  var rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  return rootContainer;
}
/**
 * Appends element as last child of body.
 */


function addRootElement(rootElem) {
  if (document.body.lastElementChild) {
    document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
  }
}
/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @param id - The id of the root element to create.
 * @returns The root element.
 */


function usePortal(id) {
  var rootElemRef = useRef(null);
  useEffect(function () {
    // Look for existing target dom element to append to
    var existingParent = document.querySelector("#".concat(id)); // Parent is either a new root or the existing dom element

    var parentElem = existingParent || createRootElement(id); // If there is no existing DOM element, add a new one.

    if (!existingParent) {
      addRootElement(parentElem);
    } // Add the detached element to the parent


    if (rootElemRef.current) {
      parentElem.appendChild(rootElemRef.current);
    }

    return function () {
      var _rootElemRef$current;

      (_rootElemRef$current = rootElemRef.current) === null || _rootElemRef$current === void 0 ? void 0 : _rootElemRef$current.remove();

      if (!parentElem.childElementCount) {
        parentElem.remove();
      }
    };
  }, [id]);
  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */

  function getRootElem() {
    if (!rootElemRef.current && true) {
      rootElemRef.current = document.createElement("div");
    }

    return rootElemRef.current;
  }

  return getRootElem();
}

export default usePortal;